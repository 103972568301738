
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2";

export default defineComponent({
  name: "vehicle-make-and-model-component",
  components: { Field, ErrorMessage },
  props: {
    widgetClasses: String,
    makeName: String,
    makeModel: String,
    modelName: String,
    modelModel: String,
    yearName: String,
    yearModel: String,
    displayValue: Boolean,
  },
  data() {
    return {
      vehicleBrands: [],
      vehicleModels: [],
      vehicleModelYears: [],
      isLoading: false,
      noResult: false,
      value: "",
      localSelectedMakeModel: "",
      localSelectedModelModel: "",
      localSelectedYearlModel: "",
    };
  },
  created() {
    // Get vehicle brands on page created
    this.getVehicleBrands();

    setTimeout(() => {
      // In page editing, trigger the dependednt vehicle model and year
      const vehicleMakeUUID = this.makeModel as string;
      const vevehicleModelUUID = this.modelModel as string;

      // Prop string is equall to UUID character length
      if (vehicleMakeUUID != undefined && vehicleMakeUUID.length == 36) {
        this.getVehicleModels(vehicleMakeUUID);
        this.getVehicleModelYears(vevehicleModelUUID);
      }
    }, 2000);
  },
  methods: {
    getVehicleBrands() {
      this.isLoading = true;
      //Fetch list of Vehicle brands
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.CARS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.isLoading = false;
            this.vehicleBrands = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getVehicleModels(value) {
      const finalValue = variables.isNonNullString(value)
        ? value
        : value.target.value;

      if (this.displayValue) {
        this.localSelectedMakeModel =
          value.target.options[value.target.selectedIndex].dataset.reference;
      } else {
        this.localSelectedMakeModel = finalValue;
      }

      //Fetch list of models of the selected Vehicle brand
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CARS_ROUTE}/${finalValue}/models`)
          .then(({ data }) => {
            //Assign data to form fields
            this.vehicleModels = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getVehicleModelYears(value) {
      const finalValue = variables.isNonNullString(value)
        ? value
        : value.target.value;

      if (this.displayValue) {
        this.localSelectedModelModel =
          value.target.options[value.target.selectedIndex].dataset.reference;
      } else {
        this.localSelectedModelModel = finalValue;
      }

      //Fetch list of years of the selected Vehicle model
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CARS_ROUTE}/model/${finalValue}/years`)
          .then(({ data }) => {
            //Assign data to form fields
            this.vehicleModelYears = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    changeYear(value) {
      const finalValue = variables.isNonNullString(value)
        ? value
        : value.target.value;

      this.localSelectedYearlModel = finalValue;
    },
    capitalize(value) {
      return variables.capitalize(value);
    },
    clearAll() {
      this.vehicleBrands;
    },
  },
  computed: {
    mainMakeModel() {
      return this.makeModel || this.localSelectedMakeModel;
    },
    mainModel() {
      return this.modelModel || this.localSelectedModelModel;
    },
    mainYear() {
      return this.yearModel || this.localSelectedYearlModel;
    },
    mainVehicleBrands() {
      return this.makeName;
    },
  },
  mounted() {
    const $vehicleBrand = $("#vehicleBrand");
    const $vehicleModel = $("#vehicleModel");
    const $vehicleYear = $("#vehicleYear");
    $vehicleBrand.select2();
    $vehicleModel.select2();
    $vehicleYear.select2();

    $vehicleBrand.on("change", this.getVehicleModels);
    $vehicleModel.on("change", this.getVehicleModelYears);
    $vehicleYear.on("change", this.changeYear);
  },
  beforeMount() {
    $("#vehicleBrand").select2("destroy");
    $("#vehicleModel").select2("destroy");
    $("#vehicleYear").select2("destroy");
    // $(this.$refs.vehicleBrand).select2("destroy");
  },
  watch: {
    mainMakeModel(newVal) {
      // Update local state when the prop changes
      this.localSelectedMakeModel = newVal;
    },
    mainModel(newVal) {
      // Update local state when the prop changes
      this.localSelectedModelModel = newVal;
    },
    mainYear(newVal) {
      // Update local state when the prop changes
      this.localSelectedYearlModel = newVal;
    },
  },
  // watch: {
  //   modelModel: {
  //     deep: true,
  //     immediate: true,
  //     handler: function(userFromProps) {
  //       if (userFromProps) {
  //         // console.log();
  //         // console.log("Title changed from " + this.makeModel);
  //         //  this.modelModel;
  //         // this.getVehicleModels(this.makeModel);
  //       }
  //     },
  //   },
  // },
});
